import "./styles.css";
import profileImg from "./profile.png"; // Import the image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
function Index() {
  return (
    <div className="mainClass">
      <div className="profile">
        <div className="profileContent">
          <h1>Ahsen Ümit ÇELİK</h1>
          <h2>Software Developer</h2>
          <div className="icons">
            <a href="mailto:aumitcelik@gmail.com">
              <FontAwesomeIcon
                icon={faEnvelope}
                size="2xl"
                style={{ color: "#ffffff" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/ahsenumitcelik/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                size="2xl"
                style={{ color: "#FFFFFF" }}
              />
            </a>
            <a
              href="https://github.com/aumitcelik"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faGithub}
                size="2xl"
                style={{ color: "#FFFFFF" }}
              />
            </a>
          </div>
        </div>
        <img src={profileImg} alt="profileImg" /> 
      </div>
    </div>
  );
}

export default Index;
